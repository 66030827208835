import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import axios from "axios";
import CacheBuster from "react-cache-buster";
import appPackage from "../package.json";
import { Helmet } from "react-helmet";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Fallback from "./Components/Fallback";
import mloTestData from "./jsons/mlo-test.json";

const Header = lazy(() => import("./Components/Header"));
const Home = lazy(() => import("./layout/Home"));
const ProductFlyers = lazy(() => import("./layout/ProductFlyers"));
const Testing = lazy(() => import("./layout/Testing"));
const Footer = lazy(() => import("./Components/Footer"));
// const Listings = lazy(() => import("./layout/Listings"));

function App({ subdomain, domain, smallDevice }) {
  const [mloSubdomain, setMloSubdomain] = useState(subdomain);
  const [currentDomain, setCurrentDomain] = useState(domain);
  const [mloData, setMloData] = useState(null);
  // const [loading, setLoading] = useState(false);
  const scrollRef = useRef(null);
  //if components has previously been loaded in session, do not hide components
  const alreadyShown = sessionStorage.getItem("showOtherComponents") || false;
  //if the ref that is set when navigating to a different page
  //is set to true or if components previously loaded, show components
  const [showOtherComponents, setShowOtherComponents] = useState(
    scrollRef.current || alreadyShown ? true : false
  );
  const pathname = useLocation().pathname;

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;
      if (percentage > 1) {
        sessionStorage.setItem("showOtherComponents", true);
        setShowOtherComponents(true);
      }
    };

    // Debounce scroll event listener for performance
    const debouncedScroll = () => {
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(handleScroll, 100);
    };

    window.addEventListener("scroll", debouncedScroll);

    return () => window.removeEventListener("scroll", debouncedScroll);
  }, [showOtherComponents]);

  useEffect(() => {
    const getMloJsonData = async () => {
      if (/\d/.test(mloSubdomain)) return;
      try {
        const jsonURL = process.env.REACT_APP_MLO_JSONS.replace(
          /{domain}/gi,
          currentDomain
        ).replace(/{mlo}/gi, mloSubdomain);
        const res = await axios.get(jsonURL);
        const [data] = res.data;
        if (data && data.name) {
          const dataCopy = { ...data };
          //if angelai link exists but does not include https:// in url
          if (
            dataCopy?.angelaiUrl &&
            !dataCopy?.angelaiUrl.includes("https://")
          ) {
            dataCopy.angelaiUrl = `https://${dataCopy.angelaiUrl}`;
          }
          //if angelai link value does not exist or is not askangel.ai link.
          if (
            !dataCopy?.angelaiUrl ||
            !dataCopy?.angelaiUrl.includes("angel")
          ) {
            dataCopy.angelaiUrl = "https://askangel.ai";
          }
          setMloData(dataCopy);
        } else {
          throw new Error("MLO json not found.");
        }
      } catch (err) {
        console.log(err);
        window.location.replace(`https://${currentDomain}.com`);
      }
    };

    getMloJsonData();
  }, [currentDomain, mloSubdomain]);

  //determine routes before rendering
  const getRoutes = () => {
    // if the url contains numbers like localhost:3000 or cloudfront urls
    // then make testing urls available.
    const isTestingEnvironment = /\d/g.test(window.location.host.split(".")[0]);

    return (
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              /\d/g.test(mloSubdomain) ? (
                <Testing
                  setCurrentDomain={setCurrentDomain}
                  setMloSubdomain={setMloSubdomain}
                />
              ) : (
                <Home
                  showOtherComponents={showOtherComponents}
                  mloSubdomain={mloSubdomain}
                  mloData={mloData}
                  scrollRef={scrollRef}
                  smallDevice={smallDevice}
                  currentDomain={currentDomain}
                />
              )
            }
          />
          <Route
            path="/:loanProduct"
            element={<ProductFlyers mloData={mloData} currentDomain={currentDomain} />}
          />
          <Route
            path="/others/:loanProduct"
            element={<ProductFlyers mloData={mloData} currentDomain={currentDomain} />}
          />
          {/* testing pages paths */}
          {isTestingEnvironment ? (
            <React.Fragment>
              <Route
                path="/staging/test"
                element={
                  <Testing
                    setCurrentDomain={setCurrentDomain}
                    setMloSubdomain={setMloSubdomain}
                  />
                }
              />
              {/* route to test performance on staging */}
              <Route
                path="/staging/wendallking"
                element={
                  <Home
                    mloSubdomain={"wendallking"}
                    mloData={mloTestData}
                    scrollRef={scrollRef}
                    showOtherComponents={showOtherComponents}
                    smallDevice={smallDevice}
                    currentDomain={"sunwestmortgage"}
                  />
                }
              />
            </React.Fragment>
          ) : null}
          {/* 404 page path */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    );
  };

  const routes = getRoutes();

  //establish helmet element for title tag before render
  const HelmetElement = (
    <Helmet>
      <title>{`${
        mloData?.name ? `${mloData?.name} | ` : ""
      }Sun West Mortgage Company, Inc.`}</title>
      <link
        rel="canonical"
        href={`https://${mloSubdomain}.${currentDomain}.com${
          pathname ? pathname : ""
        }`}
      />
    </Helmet>
  );

  return (
    <CacheBuster
      currentVersion={appPackage.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      //If public assets are hosted somewhere other than root on your server.
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <React.Fragment>
        {HelmetElement}
        <Suspense fallback={<div />}>
          <Header mloData={mloData} fetchpriority="high" />
        </Suspense>
        {routes}
        {showOtherComponents ? (
          <Suspense fallback={<div />}>
            <Footer fetchpriority="low" mloData={mloData} />
          </Suspense>
        ) : null}
      </React.Fragment>
    </CacheBuster>
  );
}

export default App;
